<template>
  <div class="app-view">
    <div class="controls-container">
      
      <div class="select-wrapper">
        <select v-model="threadId" id="select-art">
          <option v-for="art in artList" :key="art.ArtNr" :value="art.ArtNr">{{art.Bezeichnung}}</option>
        </select>
        <!-- <button @click="toggleExtend">
          <span v-if="extendFilter === true">Weniger Filter</span>
          <span v-else>Mehr Filter</span>
        </button> -->
      </div>
      <div class="control-buttons-wrapper">
        <div class="control-button">
          <button data-target="searchterm-filter" @click="switchFilter($event)"><span class="button-title">Sorte suchen</span></button>
        </div>
        <div class="control-button">
          <button data-target="sort-filter" @click="switchFilter($event)"><span class="button-title">Sortierung/Filter</span></button>
        </div>
        <div class="control-button">
          <button data-target="comparison-filter" @click="switchFilter($event)"><span class="button-title">Vergleichswert</span></button>
        </div>
      </div>
      <div class="filter-container-wrapper">
        <div class="filter-searchterm-wrapper filter-container" data-active="false" id="searchterm-filter">
          <!-- <div class="filter-searchterm-title">Sorte suchen</div> -->
          <input type="text" v-model="this.searchTerm" placeholder="Suchbegriff.." />
          <button type="button" @click="this.searchTerm = ''"></button>
        </div>
        <div class="filter-outer-wrapper filter-container" data-active="false" id="sort-filter">
          <!-- <div class="filter-wrapper-title">Sortierung/Filter</div> -->
          <div class="filter-wrapper">
            <div :class="{'single-filter': true, 'filter-range': filterFeld.Filter == 1}" v-for="(filterFeld, filterKey) in filterFelder" :key="filterKey" v-show="filterFeld.FilterErweitert !== '1' || extendFilter === true">
              <div class="filter-title">{{filterFeld.Bezeichnung}}:<span class="range-wrapper" v-if="filterFeld.Filter == 1" :class="{active: filterFelder[filterKey].selectedFilter[0] !== filterFelder[filterKey].min || filterFelder[filterKey].selectedFilter[1] !== filterFelder[filterKey].max}"><span class="mini" v-html="filterFelder[filterKey].selectedFilter[0]"></span> - <span class="maxi" v-html="filterFelder[filterKey].selectedFilter[1]"></span></span></div>
              <div class="filter-config" v-if="filterFeld.Filter == 2">
                <div class="select-wrapper">
                  <select :data-filter="filterKey" @change="filterResults()">
                    <option></option>
                    <option v-for="filterVal in filterFeld.FilterVal" :key="filterVal">{{ filterVal }}</option>
                  </select>
                </div>
              </div>
              <div class="filter-config" v-else-if="filterFeld.Filter == 1">
                
                <Slider :data-filter="filterKey" v-model="filterFelder[filterKey].selectedFilter" v-bind="filterFelder[filterKey]" @change="filterResults()" />
                
                <!-- <div class='range-slider' :class="{active: filterFelder[filterKey].selectedFilter[0] !== filterFelder[filterKey].FilterVal[0] || filterFelder[filterKey].selectedFilter[1] !== filterFelder[filterKey].FilterVal[1] }">
                  <input type="range" v-model.number="filterFelder[filterKey].selectedFilter[0]" :data-filter="filterKey" :data-init="filterFelder[filterKey].FilterVal[0]" min="0" max="9" @input="filterResults()" />
                  <input type="range" v-model.number="filterFelder[filterKey].selectedFilter[1]" :data-filter="filterKey" :data-init="filterFelder[filterKey].FilterVal[1]" min="0" max="9" @input="filterResults()" />
                </div> -->
                <!-- Vorher:
                <input type="number" v-model.number="filterFelder[filterKey].selectedFilter[0]" :data-filter="filterFeld.Key" :data-init="filterFeld.FilterVal[0]" :min="filterFeld.FilterVal[0]" :max="parseInt(parseInt(filterFeld.FilterVal[1]) - 1)" @input="filterResults()" />
                <input type="number" v-model.number="filterFelder[filterKey].selectedFilter[1]" :data-filter="filterFeld.Key" :data-init="filterFeld.FilterVal[1]" :min="parseInt(parseInt(filterFeld.FilterVal[0]) + 1)" :max="filterFeld.FilterVal[1]" @input="filterResults()" /> -->
              </div>

            </div>
            <div class="single-filter full"></div>
            <div class="single-filter">
              <div class="filter-title"></div>
              <div class="filter-config">
                <button type="button" @click="deleteFilter()">Filter löschen</button>
              </div>
            </div>
          </div>
        </div>
        <div class="comparison-outer-wrapper filter-container" data-active="false" id="comparison-filter">
          <!-- <div class="comparison-wrapper-title">Vergleichswert</div> -->
          <div class="comparison-wrapper">
            <div class="comparison-buttons-wrapper" v-show="!isMobile">
              <div class="comparison-button-wrapper">
                <button class="comparison-button" @click="activateFelder()">Alle Werte aktivieren</button>
              </div>
              <div class="comparison-button-wrapper">
                <button class="comparison-button" @click="deactivateFelder()">Alle Werte deaktivieren</button>
              </div>
            </div>

            <div class="single-comparison" v-for="(filterFeld, filterKey) in felder" :key="filterKey" v-show="filterFeld.Key !== 'sorte'">
              <div class="filter-title">
                <input type="checkbox" :id="`comparing_${filterKey}`" v-model="filterFeld.comparing">
                <label :for="`comparing_${filterKey}`">{{filterFeld.Bezeichnung}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="results-outer-wrapper">
      <!-- Erste Zeile -->
      <div class="results-compared-props">
          <div v-for="feld in compareFelder" :title="feld.Bezeichnung" :data-key="feld.Key" :data-sort="(feld.Position === 1) ? 'ASC' : null" :key="feld" @click="sortSubset($event)"><div>{{ (feld.Bezeichnung.length > 20) ? feld.Bezeichnung.substr(0, 20) + '&hellip;' : feld.Bezeichnung }}</div></div>
      </div>
      <!-- <div class="results-inner-wrapper"> -->
        <div class="single-result" v-for="thread in threadListSubset" :key="thread.Name">
          <div class="result-compare-property" v-for="feld in compareFelder" :key="feld" @click="toggleActive($event)">
            <div class="result-properties" v-if="feld.Key === 'sorte'">
              <div v-for="feld in felder" :key="feld.Key" class="result-property">
                <div class="property-key" v-if="feld.Key !== 'sorte'">{{ feld.Bezeichnung }}</div>
                <div class="property-value" v-if="feld.Key !== 'sorte'">
                  <img v-if="feld.Bild == 1 && thread.Felder[feld.Key] !== '' && thread.Felder[feld.Key] !== '0'" :src="keyImage(feld.Key, thread.Felder[feld.Key])" :alt="thread.Felder[feld.Key]" @error="replaceImage($event)" />
                  <span v-else-if="thread.Felder[feld.Key] && thread.Felder[feld.Key] !== '' && thread.Felder[feld.Key] !== '0'">{{ thread.Felder[feld.Key] }}</span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="result-property" v-if="thread.pdf">
                <div class="property-key">Produktdatenblatt</div>
                <div class="property-value"><span><a :href="`https://portal.stroetmann.de/kupoweb/archiv/SB_pdf/${threadId}_${thread.pdf.sorte}_${thread.pdf.id}.pdf`" target="_blank"><img src="../assets/icons/pdf-icon.svg"></a></span></div>
              </div>

            </div>
            <div class="property-value" v-if="feld.comparing">
              <img v-if="feld.Bild == 1 && thread.Felder[feld.Key] !== '' && thread.Felder[feld.Key] !== '0'" :src="keyImage(feld.Key, thread.Felder[feld.Key])" :alt="thread.Felder[feld.Key]" @error="replaceImage($event)" />
              <!-- <object v-if="feld.Bild == 1 && thread.Felder[feld.Key] !== '' && thread.Felder[feld.Key] !== '0'" :data="`https://portal.stroetmann.de/kupoweb/assets/sorteninfo/${feld.Key}_${thread.Felder[feld.Key].replaceAll(' ',  '')}.gif`" type="image/gif" typemustmatch onerror="testboi()" onstalled="testboi()" onabort="testboi()"><span>{{ thread.Felder[feld.Key] }}</span></object> -->
              <span v-else-if="thread.Felder[feld.Key] && thread.Felder[feld.Key] !== '' && thread.Felder[feld.Key] !== '0'">{{ thread.Felder[feld.Key] }} <span class="icon-vermehrung" v-if="feld.Key == 'sorte' && thread.Felder['in_vermehrung'] == '1'"><img src="@/assets/icon-vermehrung.svg" /></span></span>
              <span v-else>-</span>
            </div>
          </div>
        </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss">

body { margin: 0; }

.home {
  background-color: #EEEEEE;
  .app-inner {
    display: flex; flex-direction: column;
    height: 100vh;
  }
}

.controls-container {
  background-color: #EEEFEE;
  padding: 20px; padding-bottom: 0;
}

.control-buttons-wrapper {
  display: flex; flex-wrap: wrap; align-items: center;justify-content: space-between;
  margin-left: -16px; margin-right: -16px; margin-bottom: 10px;
  .control-button {
    flex: 0 0 calc(100%/3);
    padding-left: 16px; padding-right: 16px;
    button {
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
      border: 1px solid #C3C3C3; border-radius: 3px;
      color: #707070;
      display: block;
      font-size: 10px; line-height: 12px;
      padding-top: 24px; padding-bottom: 4px; padding-left: 4px; padding-right: 4px;
      position: relative;
      width: 100%;
      &:focus {
        border-color: #E73136;
        outline: none; 
      }
      &::before {
        background-position: top center; background-repeat: no-repeat; background-size: contain;
        content: '';
        display: block;
        position: absolute; top: 6px; left: 50%;
        transform: translateX(-50%);
        width: 18px; height: 18px;
      }
      .button-title { pointer-events: none; }
    }
    &:nth-child(1) button::before { background-image: url("/img/icons/icon_search.svg"); }
    &:nth-child(2) button::before { background-image: url("/img/icons/icon_sort.svg"); }
    &:nth-child(3) button::before { background-image: url("/img/icons/icon_compare.svg"); }
  }
}

.filter-searchterm-wrapper {
  padding-left: 20px; padding-right: 20px;
  position: relative;
  input[type=text] {
    margin-top: 20px; margin-bottom: 20px;
    font-size: 18px; line-height: 23px;
    padding: 9px; padding-left: 36px;
    border: 1px solid #C3C3C3;
    border-radius: 3px;
    box-sizing: border-box;
    background: #FFFFFF;
    background-image: url("/img/icons/icon_search.svg"); background-repeat: no-repeat; background-position: top 12px left 9px;
    width: 100%;
    &:focus { outline: none; }
  }
  button {
    background-color: transparent; background-image: url("/img/icons/icon_single_result_less.svg"); background-position: top center; background-repeat: no-repeat; background-size: contain;
    content: "";
    cursor: pointer;
    display: block;
    position: absolute;
    top: 32px;
    right: 32px;
    width: 19px;
    height: 19px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #C3C3C3;
    border-radius: 50%;
    &:focus { outline: none; }
  }
}
.select-wrapper {
  background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
  height: 40px;
  margin-bottom: 9px;
  position: relative;
  &::after {
    border-top: 3px solid #C3C3C3; border-right: 3px solid #C3C3C3; border-bottom: 0; border-left: 0;
    content: '';
    display: inline-block;
    padding-top: 6px; padding-right: 6px;
    position: absolute; top: 15px; right: 15px;
    transform: rotate(135deg);
  }
  select {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #C3C3C3; border-radius: 3px;
    background: transparent;
    font-size: 16px;
    position: absolute; top: 0; left: 0;
    width: 100%; height: 100%;
    padding: 9px 12px;
    &:focus { outline: none; }
  }
}
.filter-outer-wrapper {

  // margin-top: 16px;
  // margin-bottom: 16px;

  .filter-wrapper-title { margin-bottom: 16px; }
  .filter-wrapper {
    display: flex; align-items: start; flex-wrap: wrap;
    // max-height: 240px;
    // overflow-y: scroll;
    .filter-group-wrapper {
      flex: 0 0 100%;
      display: flex; align-items: center; flex-wrap: wrap;
      .filter-group-title {
        color: #B5B5B5;
        flex: 0 0 100%;
        font-size: 16px; font-weight: 700; line-height: 22px;
        padding: 9px 20px;
        text-transform: uppercase;
        border-bottom: 1px solid #dedede;
        border-top: 1px solid #dedede;
      }
      &:first-child {
        .filter-group-title {
          border-top: none;
        }
      }
    }
    
    .single-filter {
      flex: 0 0 100%;
      // margin-bottom: 16px;
      &.full { flex-basis: 100% !important; margin-bottom: 16px; }
      .filter-title {
        margin-left: 21px;
      }
      &.filter-range {
        margin-bottom: 16px;
      }
      &.filter-checkbox{
        padding: 5px 20px;
        position: relative;
        .filter-title {
          padding-left: 40px;
          position: relative;
          cursor: pointer;
          // &::before {
          //   // background-color: red;
          //   content: '';
          //   pointer-events: none;
          //   position: absolute; top: 0; left: 0;
          //   width: 30px; height: 30px;
          // }
        }
        input[type=checkbox] {
          --webkit-appearance: none;
          appearance: none;
          cursor: pointer;
          margin: 0;
          position: absolute; top: 5px; left: 20px;
          width: 30px; height: 30px;
          z-index: 1;
          background-image: url("/img/icons/check_inactive.svg");
          background-position: center; background-repeat: no-repeat; background-size: 23px;
          &:checked {
            background-image: url("/img/icons/check_active.svg");
          }
          &:focus {
            outline: none;
          }
        }
      }
      &.image-checkbox {
        input[type=checkbox]{
          &[data-img="Silomais"], &[data-img="Koernermais"], &[data-img="Biogasmais"] {
            filter: grayscale(1);
            &:checked {
              filter:grayscale(0);
            }
            &:focus {
              outline: none;
            }
          }
          &[data-img="Silomais"] {
            background-image: url("/img/icons/check_silomais.svg");
          }
          &[data-img="Koernermais"] {
            background-image: url("/img/icons/check_koernermais.svg");
          }
          &[data-img="Biogasmais"] {
            background-image: url("/img/icons/check_biogasmais.svg");
          }
        }
      }
      .filter-title {
        // margin-bottom: 8px;
        font-size: 16px; line-height: 30px;
        .range-wrapper {
          font-weight: 700;
          padding-left: 16px;
          &.active{
            color: #E73136;
          }
        }
        label {
          user-select: none;
        }
      }
      .filter-config {
        padding-left: 20px; padding-right: 20px;
        
        button {
          cursor: pointer;
          -webkit-appearance: none;
          appearance: none;
          background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
          border: 1px solid #C3C3C3; border-radius: 3px;
          color: #E73136;
          display: block;
          font-size: 16px; font-weight: bold; line-height: 20px;
          padding-top: 10px; padding-bottom: 10px;
          position: relative;
          width: 100%;
          margin-bottom: 16px;
          &:focus {
            border-color: #E73136;
            outline: none; 
          }
          &::before {
            background-position: top center; background-repeat: no-repeat; background-size: contain;
            content: '';
            display: block;
            position: absolute; top: 6px; left: 50%;
            transform: translateX(-50%);
            width: 18px; height: 18px;
          }
          .button-title { pointer-events: none; }
        }
      }
    }
  }
  .range-slider {
    width: 100%;
    margin: auto;
    text-align: center;
    position: relative;
    height: 1.5rem;
    input[type=range] {
      position: absolute;
      left: 0;
      top: .5rem;
    }

    input[type=number] {
      border: 1px solid #ddd;
      text-align: center;
      font-size: 1.6em;
      -moz-appearance: textfield;
    }

    input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button { -webkit-appearance: none; }
    input[type=number]:invalid, input[type=number]:out-of-range { border: 2px solid #ff6347; }
    input[type=range] { -webkit-appearance: none; width: 100%; }
    input[type=range]:focus { outline: none; }

    input[type=range]:nth-child(2){
      background-color: transparent;
      &::-webkit-slider-runnable-track { background-color: transparent; }
      &::-moz-range-track { background-color: transparent; }
      &:focus {
        background-color: transparent;
        &::-webkit-slider-runnable-track { background-color: transparent; }
        &::-moz-range-track { background-color: transparent; }
      }
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 12px;
      cursor: pointer;
      animate: 0.2s;
      background-image: linear-gradient(to right, #E0DFDF 0, #E0DFDF 2%, #007858 2%, #007858 98%, #E0DFDF 98%, #E0DFDF 100%);
      background-size: 10%;
      border-radius: 1px;
      box-shadow: none;
      border: 0;
    }

    input[type=range]::-webkit-slider-thumb {
      z-index: 2;
      position: relative;
      box-shadow: 0px 0px 0px #000;
      border: 1px solid #C3C3C3;
      height: 30px;
      width: 22px;
      border-radius: 3px;
      background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -8px;
    }
    &.active {
      input[type=range]::-webkit-slider-runnable-track{
        background-image: linear-gradient(to right, #E0DFDF 0, #E0DFDF 2%, #C11E22 2%, #C11E22 98%, #E0DFDF 98%, #E0DFDF 100%);
      }
    }
  }

}
.comparison-outer-wrapper {
  
  // margin-bottom: 16px;

  .comparison-wrapper-title { margin-bottom: 16px; }

  .comparison-wrapper {
    .comparison-buttons-wrapper {
      display: flex; flex-wrap: wrap;
      margin: 20px 0;
      .comparison-button-wrapper {
        flex: 0 0 50%;
        padding-left: 20px; padding-right: 20px;
      }
      button {
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        background-image: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
        border: 1px solid #C3C3C3; border-radius: 3px;
        color: #1D1D1F;
        display: block;
        font-size: 16px; line-height: 1.5;
        padding: 8px 40px;
        position: relative;
        width: 100%;
        &:focus, &.active {
          border-color: #E73136;
          color: #E73136;
          outline: none; 
        }
        .button-title { pointer-events: none; }
      }
    }
    .single-comparison {
      .filter-title {
        border-bottom: 1px solid #EEEEEE;
        position: relative;
        
        input[type=checkbox]{
          cursor: pointer;
          margin: 0;
          position: absolute; top: 0; left: 0;
          width: 100%; height: 100%;
          --webkit-appearance: none;
          appearance: none;
          border: none;
          & + label {
            cursor: pointer;
            display: block;
            font-size: 16px; line-height: 30px;
            padding-left: 20px; padding-right: 20px;
            position: relative;
            user-select: none;
            z-index: 1;
          }
          &:checked{
            & + label {
              color: white;
              background-color: #007858;
              &::after {
                border-top: 2px solid #FFFFFF; border-right: 2px solid #FFFFFF; border-bottom: 0; border-left: 0;
                content: '';
                display: inline-block;
                padding-top: 6px; padding-right: 12px;
                position: absolute; top: 8px; right: 20px;
                transform: rotate(135deg);
              }
            }
          }
          &:focus { outline: none; }
        }
      }
      &:last-child .filter-title { border-bottom: none; }
    }
  }
}

.filter-container-wrapper {
  background-color: #ffffff;
  margin-left: -20px; margin-right: -20px;
  max-height: 240px;
  overflow-y: scroll;
  text-align: left;
  &::-webkit-scrollbar { width: 0; height: 0; }
  .filter-container {
    // display: block;
    height: 0;
    overflow: hidden;
    transition: height .3s ease-in-out;
    // position: absolute; top: 100%; left: 0;
    // padding-left: 9px;
    // background-color: #FFFFFF;
    // width: calc(150% - 9px);
    // z-index: 1;
    // display: none;
    // &[data-active="true"]{
    //   display: block;
    // }
  }
}

// table tr{
//   &:first-child {
//     td { cursor: pointer; user-select: none; }
//   }
//    td img {
//     position: relative;
//     display: block;
//     margin: 0 auto;
//     width: auto;
//     height: 32px;
//     &::after {
//       content: attr(alt);
//       display: block;
//       position: absolute;
//       top: 0;
//       left: 0;
//       min-height: 100%;
//       height: auto;
//       width: 100%;
//       background-color: #ffffff;
//       z-index: 1;
//     }
//   }
// }
.results-outer-wrapper {
  background-color: #FFFFFF;
  margin: 0 auto;
  // max-width: 414px;
  // height: 100%;
  flex: 1;
  overflow: scroll;
  width: 100%;
  &::-webkit-scrollbar { width: 0; height: 0; }
  .results-compared-props {
    color: #ffffff;
    display: flex;
    font-size: 12px; line-height: 12px;
    position: relative;
    text-align: left;
    user-select: none;
    &::before {
      // content: '';
      display: block;
      height: 1px;
      background-color: #ffffff;
      position: absolute; bottom: 0; left: 0; right: 0;
      padding: 9px 12px;
    }
    > div {
      background-color: #58645A;
      border-bottom: 1px solid #ffffff;
      cursor: pointer;
      flex: 0 0 calc(100% / 3);
      padding: 9px 12px;
      position: relative;
      &::before {
        background-color: #ffffff;
        content: '';
        display: block;
        position: absolute; top: 0; left: 0; bottom: 0;
        width: 1px;
      }
      &:first-child {
        flex: 0 0 calc(200% / 3);
        > div { /*width: 40px;*/ }
        &::before { content: none; }
      }
      &:first-child:last-child {
        flex-basis: 100%;
      }
      &:last-child {

      }
      &[data-sort="ASC"]{
        &::after {
          // content: '\2191';
        }
      }
      &[data-sort="DESC"]{
        &::after {
          // content: '\2193';
        }
      }
    }
  }
  // .results-inner-wrapper {
    .single-result {
      color: #FFF;
      // cursor: pointer;
      display: flex;
      margin-bottom: 1px;
      position: relative;
      user-select: none;
      .result-title {
        background-color: #58645A;
        padding: 9px 12px;
        text-align: left;
        flex: 0 0 calc(200% / 3 );
      }
      .result-properties {
        // display: none;
        display: block;
        height: 0;
        position: absolute; top: 100%; left: 0;
        padding-left: 9px;
        background-color: #FFFFFF;
        width: 100vw;
        z-index: 10;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        .result-property {
          background-color: #E8F6F5;
          border-bottom: 1px solid #DEDEDE;
          color: #1F1F1F;
          display: flex;
          text-align: left;
          .property-key {
            flex: 0 0 60%; 
            padding: 9px 12px;
          }
          .property-value {
            // flex: 0 0 35%;
            font-weight: 700;
            padding: 9px 12px;
            width: 100%;
            word-break: break-all;
            text-align: center;
            img, object {
              display: block;
              margin: 0 auto;
              position: relative;
              width: auto; height: 32px;
              &::after {
                content: attr(alt);
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                min-height: 100%;
                height: auto;
                width: 100%;
                background-color: #E8F6F5;
                z-index: 1;
              }
            }
          }
          &:first-child {
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              border-color: transparent #003a2d transparent transparent;
              border-style: solid;
              border-width: 0 9px 9px 0;
              width: 0;
              height: 0;
              z-index: 10;
            }
          }
        }
        .result-compare-property {
          flex: 0 0 calc(100% / 3);
        }
      }
      &.active {
        .result-title {
          background-color: #007858;
        }
        // .result-properties {
        //   display: block;
        //   position: absolute; top: 100%; left: 0;
        //   width: 150%;
        //   z-index: 1;
        //   overflow: hidden;
        // }
        .result-compare-property {
          z-index: 5;
          &:first-child {
            background-color: #007858;
            &::after {
              background-image: url('/img/icons/icon_single_result_less.svg')
            }
          }
          &:first-child:last-child .result-properties { width: 100%; }
        }
      }
      .result-compare-property {
        flex: 0 0 calc(100% / 3);
        padding: 9px 12px;
        pointer-events: none;
        text-align: center;
        background-color: #58645A;
        position: relative;
        a {
          pointer-events: all;
        }
        img {
          max-height: 20px; max-width: 100%;
          width: auto;
          &::before {
            content: '';
            display: block;
            position: absolute; top: 0; left: 0; bottom: 0;
            width: 1px; background-color: #ffffff; z-index: 2;
          }
          &::after {
            content: attr(alt);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            min-height: 100%;
            height: auto;
            width: 100%;
            background-color: #58645A;
            z-index: 1;
            padding: 8px;
            box-sizing: border-box;
          }
        }
        &::before {
          background-color: #ffffff;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 1px;
        }
        &:first-child {
          flex: 0 0 calc(200% / 3 );
          text-align: left;
          position: relative;
          pointer-events: all;
          cursor: pointer;
          &::before { content: none; }
          &::after {
            background-image: url('/img/icons/icon_single_result_more.svg');
            background-position: top center; background-repeat: no-repeat; background-size: contain;
            content: '';
            display: block;
            position: absolute; top: 50%; right: 16px;
            transform: translateY(-50%);
            width: 19px; height: 19px;
          }
          .icon-vermehrung {
            margin-left: 12px;
          }
          .property-value { pointer-events: none; padding-right: 32px; }
          // position: sticky; left: 0;
        }
        &:first-child:last-child {
          //only "Sorte"
          flex-basis: calc(100%);
          .result-properties {
            width: calc(100% - 9px);
          }

        }
      }
    }
  // }
}

@media screen and(min-width: 769px) {
  .filter-outer-wrapper {
    .filter-wrapper {
      .single-filter {
        flex: 0 0 50%
      }
    }
  }
}

@media screen and(min-width: 961px) {
  .filter-outer-wrapper {
    .filter-wrapper {
      .single-filter {
        flex: 0 0 calc(100% / 3)
      }
    }
  }
}

@media screen and(min-width: 1366px) {
  .filter-outer-wrapper {
    .filter-wrapper {
      .single-filter {
        flex: 0 0 20%;
        .filter-title {
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
  }

  .results-outer-wrapper {
    display: table;
    border-collapse: separate;
    .results-compared-props {
      display: table-row; width: 100%;
      font-size: 10px;
      // position: sticky; top: 0;
      &::before {
        
      }
      > div {
        // flex: 1 1 2.5%;
        flex: 1 0 12.5%;
        padding: 4px;
        position: sticky; top: 0;
        z-index: 1;
        display: table-cell;
        overflow: hidden;
        > div {
          transform: rotate(-90deg) translateX(50%); transform-origin: center; width: 24px; margin-top: 120px; white-space: nowrap;
          pointer-events: none;
        }
        &[data-key="qualitaetsgruppe"] > div {
          min-width: 36px;
        }
        &[data-key="sorte"] > div {
          min-width: 180px;
        }
        &[data-key="zuechtervertrieb"] {
          > div {
            min-width: 48px;
          }
        }
        
        &::before {
          
        }
        &:first-child {
          flex: 1 0 25%;
        }
      }
    }
    // .results-inner-wrapper {
      font-size: 10px; line-height: 12px;

      .single-result {
        display: table-row; width: 100%;
        .result-compare-property {
          display: table-cell;
          // flex: 1 1 2.5%;
          flex: 1 0 12.5%;
          padding: 4px;
          vertical-align: middle;
          border-bottom: 1px solid #fff;
          line-height: 16px;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          &:first-child {
            flex: 1 0 25%;
          }
          .property-value {
            word-break: break-all;
          }
        }
      }
    // }
  }

}
* { box-sizing: border-box; }

</style>

<script>
import lodash from 'lodash'
import Slider from '@vueform/slider'

export default {
  name: 'Home',
  components: { Slider },
  data(){
    return {
      extendFilter: true, //eigentlich sind manche Filter versteckt
      searchTerm: '',
      selfFilterFelder: {}
      // subset: []
    }
  },
  methods: {
    replaceImage(event){
      const alt = event.target.getAttribute('alt')
      const spanEl = document.createElement('span')
      spanEl.innerHTML = alt
      event.target.parentNode.insertBefore(spanEl, event.target)
      event.target.style.display = "none"
    },
    toggleExtend(){
      this.extendFilter = !this.extendFilter
      // console.log(this.subset);
    },
    // toggleActive(thread){
    //   const isActive = thread.active;
    //   this.threadListSubset.forEach(function (subs){
    //     subs.active = false
    //   })
    //   thread.active = !isActive
    // },
    toggleActive(event){
      if(event.target.nodeName === "IMG" || event.target.nodeName === "A") return false
      const result = event.target.closest('.single-result')
      // console.log(result)
      if(result.classList.contains('active')){
        result.children[0].children[0].style.height = "0px"
        setTimeout(function(){
          result.classList.remove('active')
        }, 300)
      }
      else {
        document.querySelectorAll('.single-result').forEach(function(singRes){
          singRes.children[0].children[0].style.height = "0px"
          setTimeout(function(){
            singRes.classList.remove('active')
          }, 300)
        })
        result.children[0].children[0].style.height = result.children[0].children[0].scrollHeight + "px";
        setTimeout(function(){
          result.classList.add('active')
        }, 300)
      }
    },
    filterResults(){
      // console.log(event)
      // console.log("filterResults trigger");
      //set min/max for changed filter
      // const filterInputs = document.querySelectorAll('[data-filter="' + event.target.dataset.filter + '"]')
      // if(filterInputs.length > 1){
      //   if(event.target == filterInputs[0]){
      //     if(parseInt(filterInputs[0].value) >= parseInt(filterInputs[1].value)){
      //       filterInputs[0].value = Math.max(parseInt(filterInputs[1].value - 1), parseInt(filterInputs[0].dataset.init))
      //     }
      //     else if(parseInt(filterInputs[0].value) < parseInt(filterInputs[0].dataset.init)){
      //       filterInputs[0].value = filterInputs[0].dataset.init
      //     }
      //     else filterInputs[0].value = parseInt(filterInputs[0].value)
      //   }
      //   else if(event.target == filterInputs[1]){
      //     if(parseInt(filterInputs[1].value) <= parseInt(filterInputs[0].value)){
      //       filterInputs[1].value = Math.min(parseInt(filterInputs[0].value + 1), parseInt(filterInputs[1].dataset.init))
      //       // console.log(filterInputs[1].value)
      //     }
      //     else if(parseInt(filterInputs[1].value) > parseInt(filterInputs[1].dataset.init)){
      //       filterInputs[1].value = filterInputs[1].dataset.init
      //     }
      //     else filterInputs[1].value = parseInt(filterInputs[1].value)
      //   }
      //   // filterInputs[0].setAttribute('max', Math.max(parseInt(filterInputs[1].value) - 1, filterInputs[0].dataset.init))

      //   // filterInputs[1].setAttribute('min', Math.min(parseInt(filterInputs[0].value) + 1, parseInt(filterInputs[1].dataset.init)))
      // }

      //Filter
      const selfFilterFelder = this.felder
      // Object.values(this.felder).forEach(function(filterFeld){
      //   // if(filterFeld.comparing) console.log(filterFeld)
      //   document.querySelectorAll('[data-filter="' + filterFeld.Key+ '"]').forEach(function(inputfield, idx){
      //     if(typeof inputfield.dataset.init !== 'undefined'){
      //       //input fields
      //       if(inputfield.dataset.init !== inputfield.value){
      //         selfFilterFelder[filterFeld.Key].selectedFilter[idx] = inputfield.value
      //         this.searchTerm = ''
      //         // this.filterFelder[filterFeld.Key][idx] = inputfield.value
      //       }
      //     }
      //     else {
      //       //select
      //       if(inputfield.value !== '') {
      //         selfFilterFelder[filterFeld.Key].selectedFilter[idx] = inputfield.value
      //         this.searchTerm = ''
      //       }
      //       else selfFilterFelder[filterFeld.Key].selectedFilter[idx] = ''
      //     }
      //   })
      // })



      Object.values(this.felder).forEach((filterFeld) => {
        // if(filterFeld.comparing) console.log(filterFeld)
        document.querySelectorAll('[data-filter="' + filterFeld.Key+ '"]').forEach((inputfield, idx) => {
          // gibt es so nicht mehr
          // if(typeof inputfield.dataset.init !== 'undefined'){
          //   //input fields
          //   if(inputfield.dataset.init !== inputfield.value){
          //     selfFilterFelder[filterFeld.Key].selectedFilter[idx] = inputfield.value
          //     this.searchTerm = ''
          //     // this.filterFelder[filterFeld.Key][idx] = inputfield.value
          //   }
          // }
          if(selfFilterFelder[filterFeld.Key].Filter == 2){
            //select
            selfFilterFelder[filterFeld.Key].selectedFilter = inputfield.value
            this.searchTerm = ''
          }
          else if(selfFilterFelder[filterFeld.Key].Filter == 1){
            //range
            if(inputfield.value !== '') {
              selfFilterFelder[filterFeld.Key].selectedFilter[idx] = inputfield.value
              this.searchTerm = ''
            }
            else selfFilterFelder[filterFeld.Key].selectedFilter[idx] = ''
          }
        })
      })


      
      // console.log(selfFilterFelder);
      this.selfFilterFelder = selfFilterFelder
      // return
    },
    sortSubset(event){

      //if target is already used for current sort, reverse order, else set new target
      const eventSort = event.target.dataset.sort
        document.querySelectorAll('.results-compared-props [data-key]').forEach(function(el){
          delete el.dataset.sort
        })
      if(eventSort == 'ASC') event.target.dataset.sort = 'DESC'
      else event.target.dataset.sort = 'ASC'
      // console.log(event.target.dataset)
      const newSorting = { Key: event.target.dataset.key, order: event.target.dataset.sort }
      this.sorting = newSorting
    },
    // switchFilter(event){
    //   const targetId = event.target.dataset.target
    //   const targetEl = document.getElementById(targetId)
    //   const targetActive = targetEl.dataset.active
    //   document.querySelectorAll('.filter-container').forEach(function(filCon){
    //     filCon.dataset.active = false;
    //   })
    //   targetEl.dataset.active = (targetActive == true || targetActive == "true") ? false : true;
    //   if(targetActive == true || targetActive == "true") event.target.blur()
    // }
			switchFilter(event) {
				
			const targetId = event.target.dataset.target
			const targetEl = document.getElementById(targetId)
			const targetActive = targetEl.dataset.active
			document.querySelectorAll('.filter-container').forEach(function(filCon){
				filCon.dataset.active = false;
        filCon.style.height = "0px"
			})
			targetEl.dataset.active = (targetActive == true || targetActive == "true") ? false : true;
			event.target.dataset.active = (targetActive == true || targetActive == "true") ? false : true;
      targetEl.style.height = (targetActive == true || targetActive == "true") ? "0px" : targetEl.scrollHeight + "px";
			if(targetActive == true || targetActive == "true") event.target.blur()
			},
      activateFelder(){
        this.$store.dispatch('activateFelder')
			},
      deactivateFelder(){
        this.$store.dispatch('deactivateFelder')
      },
      keyImage(feldKey, feldVal){
        if(typeof feldKey !== 'undefined' && typeof feldVal !== 'undefined'){
          const src = `https://portal.stroetmann.de/kupoweb/assets/sorteninfo/${feldKey}_${feldVal}.gif`
          return src.replaceAll(' ', '')
        }
        else return ''
      },
      deleteFilter(){
        const threadId = this.threadId
        this.threadId = threadId
        document.querySelectorAll('[data-filter]').forEach((inputfield, idx) => {
          inputfield.value = ''
        })
      }
  },
  computed: {
    threadId: {
      get() {
        return this.$store.state.threadList.Key
      },
      set(id){
        this.$store.dispatch('updateThread', id)
        this.$store.dispatch('resetFelder', id)
        // this.$store.dispatch('resetFelder', id).then(() => {
          this.selfFilterFelder = {}
        // })
        this.extendFilter = true //eigentlich sind manche Filter versteckt
      }
    },
    threadList: {
      get(){
        return this.$store.state.threadList.list
      }
    },
    threadListSubset: {
      get(){
        // console.log("recalc threadListSubset")
        const selfFF = this.selfFilterFelder
        // console.log(Object.values(selfFF))
        let subset = this.threadList
        // console.log(this.threadList)

        //filter by search term if set
        if(this.searchTerm !== '') subset = subset.filter(thread => Object.values(thread.Felder).some(val => val.toLowerCase().includes(this.searchTerm.toLowerCase())))

        //check for applied filters
        Object.values(selfFF).forEach(function(el){
          switch(el.Filter){
            case 2: //select
              subset = subset.filter(function(thread){
                if(typeof el.selectedFilter !== 'undefined' && el.selectedFilter !== '' && thread.Felder[el.Key] !== el.selectedFilter) return false
                return thread
              })
            
            break

            case 1: //input min/max
              subset = subset.filter(function(thread){
                if(typeof el.selectedFilter[0] !== 'undefined' && parseInt(thread.Felder[el.Key]) < parseInt(el.selectedFilter[0])) return false
                if(typeof el.selectedFilter[1] !== 'undefined' && parseInt(thread.Felder[el.Key]) > parseInt(el.selectedFilter[1])) return false
                return thread
              })

            break

          }
        })

        // console.log(subset)
        if(this.sorting.Key !== ""){
          // subset.length = 15
          // console.log(subset)
          // console.log('↓');
          const currSorting = this.sorting
          switch(currSorting.order){
            case 'ASC':
                // console.log(currSorting.Key)
              subset.sort(function(a, b){
              // console.log(a.Felder[currSorting.Key])
              // console.log(b.Felder[currSorting.Key])
                if(typeof a.Felder[currSorting.Key] === 'undefined') return -1
                if(typeof b.Felder[currSorting.Key] === 'undefined') return 1
                if((!isNaN(a.Felder[currSorting.Key] ) && a.Felder[currSorting.Key] !== 0) && (!isNaN(b.Felder[currSorting.Key] && b.Felder[currSorting.Key] !== 0))) { return (a.Felder[currSorting.Key] > b.Felder[currSorting.Key]) ? 1 : -1}
                else {
                  return (a.Felder[currSorting.Key].toLowerCase() > b.Felder[currSorting.Key].toLowerCase()) ? 1 : -1
                }
              })
              break;

            case 'DESC':
                // console.log(currSorting.Key)
              subset.sort(function(a, b){
              // console.log(a.Felder[currSorting.Key])
              // console.log(b.Felder[currSorting.Key])
                if(typeof a.Felder[currSorting.Key] === 'undefined') return 1;
                if(typeof b.Felder[currSorting.Key] === 'undefined') return -1;
                if((!isNaN(a.Felder[currSorting.Key] ) && a.Felder[currSorting.Key] !== 0) && (!isNaN(b.Felder[currSorting.Key] && b.Felder[currSorting.Key] !== 0))) return (a.Felder[currSorting.Key] < b.Felder[currSorting.Key]) ? 1 : -1
                else {
                  return (a.Felder[currSorting.Key].toLowerCase() < b.Felder[currSorting.Key].toLowerCase()) ? 1 : -1
                }
                
              })
            break;
          }
          // console.log(subset)
          // subset.forEach(function(singleSet){
          //   console.log(singleSet.Felder[currSorting.Key])
          // })
        }
        return subset
      }
    },
    artList: {
      get(){
        return this.$store.state.artList
      }
    },
    felder: {
      get(){
        return this.$store.state.felder
      }
    },
    compareFelder: {
      get(){
        let preFelder = this.$store.state.felder;

        preFelder = Object.values(preFelder).filter(function(preFeld){
          // console.log(preFeld)
          if(!preFeld.comparing) return false
          return preFeld
        })
        // console.log(preFelder.length)

        return preFelder
      }
    },
    filterFelder: {
      get(){
        // console.log(this.$store.state.felder)
        const filterFelder = lodash.reduce(this.felder, function(obj, param){
            if(param.Filter !== 0) obj[param.Key] = param
            return obj
        }, {})
        // console.log(filterFelder)
        return filterFelder
      }
    },
    sorting: {
      get(){
        return {
          Key: this.$store.state.sorting.Key,
          order: this.$store.state.sorting.order
        }
      },
      set(sorting){
        this.$store.commit('updateSorting', sorting)
      }

    },
    isMobile: {
      get(){
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      }
    }
  },
  created() {
    this.$store.dispatch('init').then(() => {
    if(this.$route.query.sorte){
      // console.log(this.artList)
    }
    })
    // this.$store.dispatch('init').then(() => {
    //   const asdArr = [
    //     "Test 1",
    //     "Test 10",
    //     undefined,
    //     "Test 2",
    //     "Test 4",
    //     "Test 5",
    //     undefined,
    //     "Test 11",
    //     undefined,
    //     "Test 3",
    //     "Test 8",
    //     "Test 6",
    //     undefined,
    //     "Test 9",
    //     "Test 7",
    //     undefined,
    //   ]
    //   asdArr.sort(function(a, b){
    //     return (a > b) ? 1 : -1
    //   })
    //   console.log(asdArr);
    // })
  }
}
</script>