import { createStore } from 'vuex'
import axios from 'axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import router from '@/router'


export default createStore({
  state: {
    artList: [], //Objekt-Array von  Weizenarten
    felder: [], //Objekt-Array von Feldern/Filtern für aktuelle Weizenart
    threadList: { //ID der aktuellen Weizenart sowie zugehörige Liste von Produkten
      Key: 901,
      list: []
    },
    sorting: {
      Key: '',
      order: 'ASC'
    },
    sortenblaetter: []
  },
  mutations: {
    initArtList(state, artList){
      state.artList = artList
    },
    initSortenblatt(state, sortenblaetter){
      state.sortenblaetter = sortenblaetter
    },
    updateThread(state, thread){      
      state.threadList.Key = thread.Key
      state.threadList.list = thread.dataList
    },
    resetFelder(state, felder){
      state.felder = felder
    },
    updateSorting(state, sorting){
      state.sorting = sorting
    }
  },




  actions: {
    init({commit, dispatch, getters}){
      // axios('/johnsons/kuposaat/getSIarten.json')
      
      const getSIArtenAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/kuposaat/getSIArten.ashx', { "username": "0", "sessionkey": "" })
      const getSortenblattAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/sortenblatt/get.ashx', { "username": "0", "sessionkey": "" })
      axios.all([getSIArtenAxios, getSortenblattAxios]).then(axios.spread((...responses) => {
      // axios.post('https://portal.stroetmann.de/kupoapi_v2/kuposaat/getSIArten.ashx', { "username": "0", "sessionkey": "" })
      // .then(response => {
        if(responses[1].data && responses[1].data.data && responses[1].data.data.length > 0){
          commit('initSortenblatt', responses[1].data.data)
        }
        if(responses[0].data.artList.length && responses[0].data.artList && responses[0].data.artList.length > 0){
          commit('initArtList', responses[0].data.artList)

          let artNr = getters.getThreadId
          if(router.currentRoute.value.query.sorte){
            responses[0].data.artList.forEach(function(art: any){
              if(router.currentRoute.value.query.sorte == art.Bezeichnung) artNr = art.ArtNr
            })
          }
          dispatch('updateThread', artNr)
        }
      }))
    },
    updateThread({commit, dispatch, getters}, id){
      const thread = {
        Key: Number,
        dataList: []
      }
      
      thread.Key = id
      
      // axios('/johnsons/kuposaat/getSI_threads-' + id + '.json')
      axios.post('https://portal.stroetmann.de/kupoapi_v2/kuposaat/getSI_threads.ashx', { "username": "0", "sessionkey": "", "artenschluessel": [id] })
      .then(response => {
        if(response.data.list.length && response.data.list[0] && response.data.list[0].Value && response.data.list[0].Value.length > 0){
          const currSortenblaetter = lodash.cloneDeep(getters.getSortenblaetter).find(function(sortenblatt: any){
            if(sortenblatt.id == thread.Key) return sortenblatt
          })
          if(typeof currSortenblaetter !== 'undefined'){
            // console.log(response.data.list[0].Value)
            response.data.list[0].Value.forEach(function(entry: any){
              entry.Felder.forEach(function(feld: any, idx: any){
                entry.Felder[feld.Key] = feld.Value
                entry.active = false
                const sortenblatt =  currSortenblaetter.sorten.find(function(sorte: any){
                  if(sorte.sorte == entry.Name) return sorte
                })
                if(typeof sortenblatt !== 'undefined') entry.pdf = sortenblatt
                delete entry.Felder[idx]
              })
            })
          }
          else {
            response.data.list[0].Value.forEach(function(entry: any){
              entry.Felder.forEach(function(feld: any, idx: any){
                entry.Felder[feld.Key] = feld.Value
                entry.active = false
                delete entry.Felder[idx]
              })
            })
          }
          // console.log(response.data.list[0].Value)
          thread.dataList = response.data.list[0].Value
          dispatch('resetFelder', id)
          // console.log(thread.dataList)
          commit('updateThread', thread)
        }
      })
    },
    resetFelder({commit, dispatch}, id){
      // axios('/johnsons/sorteninfo/felder/get-' + id + '.json')
      axios.post('https://portal.stroetmann.de/kupoapi_v2/sorteninfo/felder/get.ashx', { "username": "0", "sessionkey": "", "artenschluessel": id})
      .then(response => {
        if(response.data && response.data.data && response.data.data.length > 0){

          const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

          const responseByKey = lodash.reduce(response.data.data , function(obj: any, param: any) {
            obj[param.Key] = param
            if(obj[param.Key].Filter == 2){
              obj[param.Key].selectedFilter = ''
            }
            else {
              obj[param.Key].selectedFilter = obj[param.Key].FilterVal.slice()
              if(obj[param.Key].Filter == 1) obj[param.Key].min = obj[param.Key].FilterVal[0]
              if(obj[param.Key].Filter == 1) obj[param.Key].max = obj[param.Key].FilterVal[1]
              if(obj[param.Key].Filter == 1) obj[param.Key].tooltips = false
            }
            if(isMobile) obj[param.Key].comparing = (obj[param.Key].Key === 'sorte')
            else obj[param.Key].comparing = true
            return obj
           }, {})
          //  console.log('this.felder:')
          //  console.log(responseByKey)
           Object.values(responseByKey).forEach(function(singleFeld: any){
            if(singleFeld.Position === 1){
              const sorting = {Key: singleFeld.Key, order: 'ASC'}
              commit('updateSorting', sorting)
            }
           })


          commit('resetFelder', responseByKey)
        }
      })
    },
    activateFelder({commit, getters}){

      const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

      // console.log(getters.getFelder)

      const responseByKey = lodash.reduce(getters.getFelder , function(obj: any, param: any) {
        obj[param.Key] = param
        obj[param.Key].comparing = true
        return obj
       }, {})
      // console.log(responseByKey)
      commit('resetFelder', responseByKey)
    },
    deactivateFelder({commit, getters}){

      const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

      // console.log(getters.getFelder)

      const responseByKey = lodash.reduce(getters.getFelder , function(obj: any, param: any) {
        obj[param.Key] = param
        obj[param.Key].comparing = (obj[param.Key].Key === 'sorte')
        return obj
       }, {})
      // console.log(responseByKey)
      commit('resetFelder', responseByKey)
    }
  },
  modules: {
  },
  getters: {
    getFelder: state => state.felder,
    getThreadId: state => state.threadList.Key,
    getSortenblaetter: state => state.sortenblaetter
  }
})
